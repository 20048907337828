// REMOVE_SHAKA: FILE
import { useContinuousPlayEnabled } from '@grandstand-web/bally-web-shared/src/hooks/useContinuousPlayEnabled'
import { isConnectedWeb } from '@grandstand-web/bally-web-shared/src/utils/appUtils'
import { isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'
import { useVideoServiceShaka } from '../../hooks/useVideoService/useVideoServiceShaka'
import { PlayerType, TSubPlayerProps } from '../../types'
import NextIdDisplay from '../NextIdDisplay'
import CommonVideoErrorView from '../VideoErrorView'
import VideoLoadingOrBuffering from '../VideoLoadingOrBuffering'

const videoPlayerLogger = Logger.of('PlayerShaka')

export const PlayerShaka = ({
  useOwnBufferSpinner,
  disableLoadingSpinner,
  playerType = PlayerType.Shaka,
  videoId,
  ...props
}: TSubPlayerProps) => {
  const Player = require('./PlayerShakaDynamic').default
  const router = useRouter()
  const { error, src } = useVideoServiceShaka({
    videoId,
    useRouter,
    playerType,
  })
  const { isWebContinuousPlayEnabled, isConnectedWebContinuousPlayEnabled } = useContinuousPlayEnabled()
  const SelectedVideoErrorView = props.VideoErrorView ?? CommonVideoErrorView
  const videoSpinnerRef = useRef()

  useEffect(() => {
    videoPlayerLogger.debug('src changed', { src })
  }, [src])

  const rawVideoResponse = src?.raw
  const drmLicenseUrl = rawVideoResponse?.drm?.licenseUrl
  const drmToken = rawVideoResponse?.drm?.token
  const deviceInfo = getWindowDeviceInfo()

  const handleOnBufferingStart = useCallback(() => {
    if (useOwnBufferSpinner) {
      // @ts-ignore
      videoSpinnerRef?.current?.setIsBuffering(true)
    }
  }, [useOwnBufferSpinner])

  const handleOnBufferingEnd = useCallback(() => {
    // @ts-ignore
    videoSpinnerRef?.current?.setIsBuffering(false)
    // @ts-ignore
    videoSpinnerRef?.current?.declareVideoLoaded()
  }, [])

  const handleOnPlay = useCallback(() => {
    // @ts-ignore
    videoSpinnerRef?.current?.declareVideoLoaded()
  }, [])

  const handleOnPlayerLoaded = useCallback(() => {}, [])

  const handleVideoPlaybackEnded = useCallback(() => {
    if (src?.raw.next_id) {
      const connectedWeb = isConnectedWeb()
      const nextVideoBasePath = connectedWeb ? 'video' : 'game-details'
      if ((connectedWeb && isConnectedWebContinuousPlayEnabled) || (!connectedWeb && isWebContinuousPlayEnabled)) {
        router.push(`/${nextVideoBasePath}/${src?.raw.next_id}`)
      }
    } else {
      // Back to home
      router.push('/')
    }
  }, [isConnectedWebContinuousPlayEnabled, router, src?.raw.next_id, isWebContinuousPlayEnabled])

  const getConfigDrm = () => {
    if (!drmLicenseUrl || !drmToken) {
      // there is no drm to configure
      return undefined
    }

    // fairplay
    if (deviceInfo.browser === 'safari') {
      return {
        servers: { 'com.apple.fps': drmLicenseUrl ?? '' },
        advanced: {
          'com.apple.fps': {
            serverCertificateUri: drmLicenseUrl ? `${drmLicenseUrl}cert/ballysports` : undefined,
          } as shaka.extern.AdvancedDrmConfiguration,
        },
      } as unknown as shaka.extern.DrmConfiguration
    }

    // playready
    if (deviceInfo.platform === 'tv_xboxone') {
      return {
        servers: {
          'com.microsoft.playready': drmLicenseUrl,
        },
      }
    }

    // widevine
    return {
      servers: { 'com.widevine.alpha': drmLicenseUrl ?? '' },
    } as unknown as shaka.extern.DrmConfiguration
  }

  const config: any = {
    manifest: {
      segmentRelativeVttTiming: true,
    },
    drm: getConfigDrm(),
  }

  videoPlayerLogger.debug('shaka config', { src, config })

  if (error) {
    const shouldRenderErrorOverlay = props.shouldRenderErrorOverlay ?? true
    return shouldRenderErrorOverlay ? <SelectedVideoErrorView error={error} /> : <></>
  }

  return (
    <>
      {src?.src && (
        <Player
          drmToken={drmToken}
          config={config}
          src={src}
          chromeless={props.chromeless ?? false}
          muted={props.muted ?? false}
          onPlayerCanPlayThrough={props.onPlayerCanPlayThrough}
          shouldTrackVideoAnalytics={props.shouldTrackVideoAnalytics}
          startTime={props.videoStartTime}
          start={props.start}
          poster={props.image}
          onPlayerLoaded={handleOnPlayerLoaded}
          onPlay={handleOnPlay}
          onPlaybackEnded={handleVideoPlaybackEnded}
          onBufferingStart={handleOnBufferingStart}
          onBufferingEnd={handleOnBufferingEnd}
        >
          {props.children}
        </Player>
      )}
      <VideoLoadingOrBuffering ref={videoSpinnerRef} disableLoadingSpinner={disableLoadingSpinner} />
      {isNotInProd() && <NextIdDisplay nextId={src?.raw.next_id} />}
    </>
  )
}

export default PlayerShaka
